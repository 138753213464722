
.bg {
    min-height: 510px;
    background-color: #bddef0;
}
.error-404 {
    background:  url("https://www.principalcdn.com/css/horizon/v2/carroll-the-librarian.svg") no-repeat;
    background-size: auto 440px;
    background-position: bottom center;
    overflow: hidden;
    min-height: 500px;
}
@media (max-width: 768px) {
    .error-404 {
        min-height: 450px;
        background-size: auto 300px;
    }
}
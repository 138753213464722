html {
  position: relative;
  min-height: 100%;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height:100px;
  font-weight: bolder;
  }

body {
  margin: 0 0 calc(226px + 2rem); /* This is the footer offset. Must match footer height + footer top margin */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.collapse-section .collapse-heading label {
    cursor: pointer;
    position: relative;
    margin-bottom: 6px;
    width: 100%;
}

.collapse-section .collapse-heading .collapse-title {
    font-family: "FS Elliot Web Bold","FS Elliot Web Regular","Arial",arial,sans-serif;
    padding-left: 18px;
}

.collapse-section .collapse-heading input[type='checkbox']:checked+label:before {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.collapse-section .collapse-heading input:not(.old) {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.collapse-section .collapse-heading label:before {
    position: absolute;
    font-size: 22px;
    top: -5px;
    font-family: FontAwesome;
    content: "";
    color: #0091da;
    -webkit-transition: ease-in-out all 0.25s;
    -o-transition: ease-in-out all 0.25s;
    transition: ease-in-out all 0.25s;
    -webkit-transform-origin: 2.5px center;
    -ms-transform-origin: 2.5px center;
    transform-origin: 2.5px center;
}

.modal-body {
    word-wrap: break-word;
}
